import { Box, Typography } from '@mui/material';
import { THEME_COLORS } from 'constants/colors';
import { ERROR_CODE } from 'constants/common';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CancelIcon from '@mui/icons-material/Cancel';
import React, {ChangeEvent, useRef, useState} from 'react';
import { CropImagePopup } from 'components/CropImage';
import { ImageOptions } from 'utils/image.utils';

interface ImageUploaderProps {
    setUserMessage: any,
    setSubmitedImage: any,
    submitedImage: ImageOptions | null,
    title: string,
    description: string,
    imageSize: number,
    style?: React.CSSProperties;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
    setUserMessage,
    setSubmitedImage,
    submitedImage,
    title,
    imageSize,
    description
}) => {
    const imageInputRef = useRef<HTMLInputElement>(null);
    const [image, setImage] = useState('');
    const [showCropImagePopup, setShowCropImagePopup] = useState(false);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(URL.createObjectURL(file));
                setShowCropImagePopup(true);
            } else {
                setUserMessage({
                    message: 'File format isn\'t accepted',
                    code: ERROR_CODE
                });
            }
        }
    };

    const ImageUploadInput = () => {
        return (
            <input
                type="file"
                accept="image/*"
                ref={imageInputRef}
                style={{display: 'none'}}
                onChange={handleFileChange}
            />
        );
    };

    return (
        <>
            <Box display={'flex'} flexDirection={'column'} gap={'8px'} width={'50%'} onClick={() => imageInputRef?.current?.click()}>
                <Typography variant='h6'>{title}</Typography>
                <Box p={'64px 16px'} height={`${imageSize}px`} display={'flex'} gap={'16px'} alignItems='center' flexDirection={'column'}
                    border={submitedImage ? `1px solid ${THEME_COLORS.primaryEnaibledBorder}` : `1px dashed ${THEME_COLORS.primaryFocus}`}
                    borderRadius={'8px'} sx={{ cursor: `${submitedImage ? 'auto' : 'pointer'}`, position: 'relative' }}>

                    {submitedImage && 
            <CancelIcon sx={{
                position: 'absolute',
                top: '-14px',
                right: '-14px',
                width: '28px',
                height: '28px',
                cursor: 'pointer',
                zIndex: 2,
                color: `${THEME_COLORS.primaryOutlinedBorder}`,
                background: `${THEME_COLORS.primaryContrast}`
            }} onClick={(e) => {
                e.stopPropagation();
                setSubmitedImage(null);
            }} />
                    }

                    {submitedImage ? 
                        <Box
                            component="img"
                            sx={{ height: `${imageSize}px`, width: `${imageSize}px`, borderRadius: '8px', border: `1px solid ${THEME_COLORS.primaryEnaibledBorder}` }}
                            alt={'Category icon'}
                            src={submitedImage.src?.toString()}
                        /> : <>
                            <ImageUploadInput/>
                            <UploadFileIcon fontSize='medium'/>
                            <Typography variant='body1' color={THEME_COLORS.textPrimary}>Click to upload</Typography>
                            <Typography variant='body2' color={THEME_COLORS.textSecondary}>{description}</Typography>
                        </>
                    }
                </Box>
            </Box>


            {showCropImagePopup && <CropImagePopup
                open={showCropImagePopup}
                image={image}
                setSubmittedImage={setSubmitedImage}
                setUserMessage={setUserMessage}
                close={() => setShowCropImagePopup(false)} 
            />}
        </>
    );
};


export default ImageUploader;
