import { Box, Typography } from '@mui/material';
import { THEME_COLORS } from 'constants/colors';
import React from 'react';

interface SettingContainerProps {
  title: string;
  subtitle?: string;
  InputComponent?: React.ReactNode;
}

const SettingContainer: React.FC<SettingContainerProps> = ({
    title,
    subtitle,
    InputComponent
}) => {
    return (
        <Box 
            display="flex" 
            flexDirection="column"
        >
            <Typography variant='h6'>
                {title}
            </Typography>
            <Typography 
                variant='body2'
                color={THEME_COLORS.primaryLight}
                marginBottom={2}
            >
                {subtitle}
            </Typography>
            {InputComponent}
        </Box>
    );
};

export default SettingContainer;