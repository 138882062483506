import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { getSavedAccessToken } from 'utils/localstorage.utils';
import { ROUTES } from 'constants/common';

interface Props {
    body: JSX.Element;
    isProtected?: boolean;
}

const NavsLayout = ({ body, isProtected = true }: Props) => {
    const auth = getSavedAccessToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (isProtected && !auth) {
            navigate(ROUTES.Login);
        }
    }, []);

    return (
        <>
            <Box position="relative" width="100%" minHeight="100vh">
                <Box width="100%" minHeight="100vh">
                    {body}
                </Box>
            </Box>
        </>
    );
};

export default NavsLayout;