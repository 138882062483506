import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ERROR_CODE, CATEGORIES_PAGE_MODE, SUCCESS_CODE } from '../../../../constants/common';
import { THEME_COLORS } from 'constants/colors';
import axiosService from 'requests/axios';
import { CategoryResponse } from 'interfaces/responses/Category';
import { formatDateString } from 'utils/other.utils';
import AgreeDialog from 'components/AgreeDialog';
import LoadingContainer from 'components/LoadingContainer';
import SearchBar from 'components/SearchBar';
import SortBy from 'components/SortBy';
import PageWrapper, { WrapperType } from 'components/PageWrapper';

const CategoriesColumns = ['No.', 'Icon', 'Name', 'Created At', 'Actions'];

interface CategoryTableProps {
    setMessage: any,
    setCategoriesMode: any,
    setCategoryToEdit: any,
}

const CategoriesTable: React.FC<CategoryTableProps> = ({
    setMessage,
    setCategoriesMode,
    setCategoryToEdit,
}) => {
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [categories, setCategories] = useState<CategoryResponse[]>([]);
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState('desc');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [categoryToDelete, setCategoryToDelete] = useState<CategoryResponse | null>(null);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const receiveCategories = () => {
        setLoading(true);
        let initialUrl = `category/?page=${page + 1}&limit=${limit}&sort=${sortBy}`;

        if (search) {
            initialUrl += `&search=${search}`;
        };

        axiosService.get(initialUrl)
            .then((res: any) => {
                setCategories(res.data.items);
                setTotal(res.data.total);
            })
            .catch(() => {
                setMessage({
                    message: 'Something went wrong!',
                    code: ERROR_CODE
                });
            }).finally(() => {
                setLoading(false);
            });
    };

    const onDeleteCategory = () => {
        if (categoryToDelete) {
            axiosService.delete(`/category/${categoryToDelete.id}`)
                .then(() => {
                    setMessage({
                        message: `Category ${categoryToDelete?.name} successfully deleted!`,
                        code: SUCCESS_CODE
                    });
                    setDeleteDialogOpen(false);
                    receiveCategories();
                })
                .catch(() => {
                    setMessage({
                        message: 'Something went wrong!',
                        code: ERROR_CODE
                    });
                });
        };
    };

    useEffect(() => {
        if (search !== null) {
            setLoading(true);
            const delayDebounceFn = setTimeout(() => {
                receiveCategories();
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [search, limit, page, sortBy]);

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setLimit(+event.target.value);
        setPage(0);
    };

    return (
        <Box>
            <Box>
                <Grid direction="row" display={'flex'} justifyContent={'space-between'} pb='18px'>
                    <Typography variant='h5'> Sparkeey Categories</Typography>
                    <Button variant="contained" color="secondary" size="large" onClick={() => setCategoriesMode(CATEGORIES_PAGE_MODE.ADD)}>Add category</Button>
                </Grid>
                <PageWrapper type={WrapperType.TABLE}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <SearchBar setSearch={setSearch} />
                        <SortBy setSortBy={setSortBy} sortBy={sortBy} />
                    </Box>
                    {loading ?
                        <LoadingContainer /> : <>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {CategoriesColumns.map((column, i) => (
                                                <TableCell key={i}>{column}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {categories.map((el: CategoryResponse, index: number) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={el.id}>
                                                    <TableCell>
                                                        {page * limit + index + 1}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box
                                                            component="img"
                                                            sx={{ height: '32px', width: '32px', borderRadius: '8px', border: `1px solid ${THEME_COLORS.primaryEnaibledBorder}` }}
                                                            alt={`${el.name} icon`}
                                                            src={el.icon}
                                                        />
                                                    </TableCell>
                                                    <TableCell> {el.name} </TableCell>
                                                    <TableCell> {formatDateString(el.createdAt)} </TableCell>
                                                    <TableCell width={'5%'}>
                                                        <Box display={'flex'} flexDirection={'row'} gap={'16px'}>
                                                            <Button size='small' variant='outlined' color='primary' onClick={() => (setCategoryToEdit(el), setCategoriesMode(CATEGORIES_PAGE_MODE.EDIT))}>Edit</Button>
                                                            <Button size='small' variant='outlined' color='error' onClick={() => (setCategoryToDelete(el), setDeleteDialogOpen(true))}>Delete</Button>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={total}
                                rowsPerPage={limit}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> </>}
                </PageWrapper>
            </Box>

            <AgreeDialog open={deleteDialogOpen} close={() => setDeleteDialogOpen(false)} title='Delete a Category' submitButton='Delete'
                description={`Do you really want to delete the '${categoryToDelete?.name}' category?`} onConfirm={() => onDeleteCategory()} />

        </Box>
    );
};

export default CategoriesTable;
