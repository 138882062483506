import React, { useState } from 'react';
import { Box } from '@mui/material';
import { AlertMessageType, CATEGORIES_PAGE_MODE } from '../../constants/common';
import AlertMessage from '../../components/AlertMessage';
import MenuLayout from 'layouts/MenuLayout';
import { Footer } from 'components/Footer';
import CategoryForm from 'pages/Categories/components/CategoryForm';
import CategoriesTable from './components/CategoriesTable';
import { CategoryResponse } from 'interfaces/responses/Category';


export default function Categories() {
    const [message, setMessage] = useState<AlertMessageType | null>(null);
    const [categoriesMode, setCategoriesMode] = useState<string>(CATEGORIES_PAGE_MODE.GET);
    const [categoryToEdit, setCategoryToEdit] = useState<CategoryResponse | null>(null);

    const handleModeContent = () => {
        if (categoriesMode == CATEGORIES_PAGE_MODE.ADD) {
            return (<CategoryForm mode={CATEGORIES_PAGE_MODE.ADD} setCategoriesMode={setCategoriesMode} setUserMessage={setMessage}/>);
        } else if (categoriesMode == CATEGORIES_PAGE_MODE.EDIT) {
            return (<CategoryForm mode={CATEGORIES_PAGE_MODE.EDIT} category={categoryToEdit} setCategoriesMode={setCategoriesMode} setUserMessage={setMessage}/>);
        } else {
            return (<CategoriesTable setMessage={setMessage} setCategoriesMode={setCategoriesMode} setCategoryToEdit={setCategoryToEdit}/>);
        }
    };

    return (
        <MenuLayout>
            <Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} width='100%'>
                {message && (
                    <AlertMessage
                        setMessage={setMessage}
                        message={message?.message}
                        code={message?.code}
                    />
                )}

                {handleModeContent()}
                <Footer />
            </Box>
        </MenuLayout>
    );
}
