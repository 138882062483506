import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import AgreeDialog from 'components/AgreeDialog';
import LoadingContainer from 'components/LoadingContainer';
import PageWrapper, { WrapperType } from 'components/PageWrapper';
import { AlertMessageType, ERROR_CODE, SUCCESS_CODE } from 'constants/common';
import { User } from 'interfaces/common/user';
import React, { useCallback, useEffect, useState } from 'react';
import axiosService from 'requests/axios';
import { UsersColumns } from '../../constants/usersColumns';
import UsersFilters from '../UsersFilters';
import UsersTableRow from '../UsersTableRow';

interface UsersTableProps {
  setMessage: React.Dispatch<React.SetStateAction<AlertMessageType | null | undefined>>
}

const UsersTable: React.FC<UsersTableProps> = ({ setMessage }) => {
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState('desc');

    const [allUsers, setAllUsers] = useState<User[]>([]);
    const [allUsersLoading, setAllUsersLoading] = useState<boolean>(true);
    const [dialogSubmitButtonLoading, setDialogSubmitButtonLoading] = useState<boolean>(false);

    const [userToBlock, setUserToBlock] = useState<User | null>(null);
    const [blockDialogOpen, setBlockDialogOpen] = useState<boolean>(false);
    const [userToDelete, setUserToDelete] = useState<User | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setLimit(+event.target.value);
        setPage(0);
    };

    const receiveAllUsers = useCallback(() => {
        setAllUsersLoading(true);

        let initialUrl = `user/?page=${page + 1}&limit=${limit}&sort=${sortBy}`;

        if (search) {
            initialUrl += `&search=${search}`;
        };

        axiosService.get(initialUrl)
            .then((res) => {
                setAllUsers(res.data.users);
                setTotal(res.data.total);
            })
            .catch(() => {
                setMessage({
                    message: 'Something went wrong!',
                    code: ERROR_CODE
                });
            })
            .finally(() => {
                setAllUsersLoading(false);
            });
    }, [sortBy, search, page, limit]);

    const onUserBlock = useCallback(() => {
        if (!userToBlock) {
            return;
        }

        setDialogSubmitButtonLoading(true);

        axiosService.patch(`user/block/${userToBlock.id}`)
            .then(() => {
                setBlockDialogOpen(false);
                setMessage({
                    message: `User ${userToBlock.blocked ? 'unblocked' : 'blocked'} successfully!`,
                    code: SUCCESS_CODE
                });
                receiveAllUsers();
            })
            .catch(() => {
                setMessage({
                    message: 'Something went wrong!',
                    code: ERROR_CODE
                });
            })
            .finally(() => {
                // Enable the submit button only after the dialog closing animation ends
                setTimeout(() => {
                    setDialogSubmitButtonLoading(false);
                }, 150);
            });
    }, [userToBlock, setMessage, receiveAllUsers]);

    const onUserDelete = useCallback(() => {
        if (!userToDelete) {
            return;
        }

        setDialogSubmitButtonLoading(true);

        axiosService.delete(`user/${userToDelete.id}`)
            .then(() => {
                setDeleteDialogOpen(false);
                setMessage({
                    message: 'User deleted successfully!',
                    code: SUCCESS_CODE
                });
                receiveAllUsers();
            })
            .catch(() => {
                setMessage({
                    message: 'Something went wrong!',
                    code: ERROR_CODE
                });
            })
            .finally(() => {
                // Enable the submit button only after the dialog closing animation ends
                setTimeout(() => {
                    setDialogSubmitButtonLoading(false);
                }, 150);
            });
    }, [userToDelete, setMessage, receiveAllUsers]);

    useEffect(() => {
        receiveAllUsers();
    }, [receiveAllUsers]);

    return (
        <PageWrapper type={WrapperType.TABLE}>
            <UsersFilters
                setSearch={setSearch}
                sortBy={sortBy}
                setSortBy={setSortBy}
            />
            {allUsersLoading ?
                <LoadingContainer /> : <>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {UsersColumns.map((column, i) => (
                                        <TableCell 
                                            key={i}
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                pr: 4
                                            }}
                                        >
                                            {column}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allUsers.map((user: User, index: number) => {
                                    return (
                                        <UsersTableRow
                                            user={user}
                                            handleUserBlock={() => {
                                                setBlockDialogOpen(true);
                                                setUserToBlock(user);
                                            }}
                                            handleUserDelete={() => {
                                                setDeleteDialogOpen(true);
                                                setUserToDelete(user);
                                            }}
                                            key={user.id}
                                            index={page * limit + index + 1}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={total}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
            <AgreeDialog
                open={blockDialogOpen}
                close={() => setBlockDialogOpen(false)}
                title={`${userToBlock?.blocked ? 'Unblock' : 'Block'} user`}
                submitButton={userToBlock?.blocked ? 'Unblock' : 'Block'}
                description={`Do you really want to ${userToBlock?.blocked ? 'unblock' : 'block'} ${userToBlock?.firstName} ${userToBlock?.lastName}?`}
                onConfirm={onUserBlock}
                loading={dialogSubmitButtonLoading}
            />
            <AgreeDialog
                open={deleteDialogOpen}
                close={() => setDeleteDialogOpen(false)}
                title="Delete user"
                submitButton="Delete"
                description={`Do you really want to delete ${userToDelete?.firstName} ${userToDelete?.lastName}?`}
                onConfirm={onUserDelete}
                loading={dialogSubmitButtonLoading}
            />
        </PageWrapper>
    );
};

export default UsersTable;