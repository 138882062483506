import React, { useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import { ROUTES } from 'constants/common';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { getSavedUserEmail } from 'utils/localstorage.utils';

const drawerWidth = 256;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const textTransitionMixin = (open: boolean): CSSObject => ({
    opacity: open ? 1 : 0,
    transition: open
        ? 'opacity 0.25s ease-in-out 0.05s'
        : 'opacity 0.15s ease-in-out',
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));

interface Props {
    children: React.ReactNode;
    isProtected?: boolean;
}

const MENU_ITEMS = [
    {
        value: 'Sparkeey Categories',
        icon: <InboxIcon />,
        link: ROUTES.Categories
    },
    { value: 'Settings', icon: <SettingsIcon />, link: ROUTES.Settings },
    { value: 'Users', icon: <PeopleIcon />, link: ROUTES.Users }
];

const MenuLayout = ({ children }: Props) => {
    const [open, setOpen] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleLogout = () => {
        setAnchorEl(null);
        localStorage.clear();
        navigate(ROUTES.Login);
    };

    return (
        <Box>
            <AppBar position='fixed' sx={{zIndex: 1}}>
                <Toolbar>
                    <Typography
                        fontSize="20px"
                        fontWeight={500}
                        component="div"
                        sx={{
                            flexGrow: 1,
                            color: (theme) => theme.palette.primary.contrastText
                        }}
                    >
                        Sparkeey
                    </Typography>
                    {
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                                color="inherit"
                            >
                                <AccountCircle
                                    sx={{ width: '2rem', height: '2rem' }}
                                />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                                sx={{ top: 40 }}
                            >
                                <MenuItem disabled={true} divider={true}>
                                    {getSavedUserEmail()}
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Sign Out</ListItemText>
                                </MenuItem>
                            </Menu>
                        </div>
                    }
                </Toolbar>
            </AppBar>
            <Box display="flex" sx={{overflowX: 'hidden'}}>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setOpen(!open)}
                            edge="start"
                        >
                            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <List>
                        {MENU_ITEMS.map((item, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                sx={{ display: 'block' }}
                                onClick={() => navigate(item.link)}
                            >
                                <ListItemButton
                                    selected={pathname === item.link}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: 'center',
                                        px: 2.5
                                    }}
                                >
                                    <ListItemText
                                        primary={item.value}
                                        sx={textTransitionMixin(open)}
                                    />
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: '2rem', pb: '0', overflowX: 'auto', marginTop: '60px'}}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default MenuLayout;