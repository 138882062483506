import { LOCALSTORAGE_KEYS } from 'constants/common';

// ACCESS TOKEN
export const saveAccessToken = (token: string) => {
    localStorage.setItem(LOCALSTORAGE_KEYS.ACCEESS_TOKEN, token);
};

export const getSavedAccessToken = () => {
    return localStorage.getItem(LOCALSTORAGE_KEYS.ACCEESS_TOKEN);
};

// REFRESH TOKEN
export const saveRefreshToken = (token: string) => {
    localStorage.setItem(LOCALSTORAGE_KEYS.REFRESH_TOKEN, token);
};

export const getSavedRefreshToken = () => {
    return localStorage.getItem(LOCALSTORAGE_KEYS.REFRESH_TOKEN);
};

// USER ID
export const saveUserID = (userID: string) => {
    localStorage.setItem(LOCALSTORAGE_KEYS.USER_ID, userID);
};

export const getSavedUserID = () => {
    return localStorage.getItem(LOCALSTORAGE_KEYS.USER_ID);
};

// USER Email
export const saveUserEmail = (email: string) => {
    localStorage.setItem(LOCALSTORAGE_KEYS.USER_EMAIL, email);
};

export const getSavedUserEmail = () => {
    return localStorage.getItem(LOCALSTORAGE_KEYS.USER_EMAIL);
};
