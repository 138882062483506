import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ValidUrl } from 'interfaces/common/validUrl';
import React, { useCallback, useMemo } from 'react';

interface UrlSelectorProps {
  allValidUrlsOptions: ValidUrl[];
  selectedValidUrlIds: number[];
  setSelectedValidUrlIds:  React.Dispatch<React.SetStateAction<number[]>>;
}

const UrlSelector: React.FC<UrlSelectorProps> = ({
    allValidUrlsOptions,
    selectedValidUrlIds,
    setSelectedValidUrlIds,
}) => {
    const selectedValidUrls = useMemo(() => (
        allValidUrlsOptions.filter((option) => selectedValidUrlIds.includes(option.id))
    ), [allValidUrlsOptions, selectedValidUrlIds]);

    const handleValidUrlsChange = (e: SelectChangeEvent<number[]>) => {
        setSelectedValidUrlIds(e.target.value as number[]);
    };

    const renderValue = useCallback(() => (
        selectedValidUrls.length ?
            selectedValidUrls
                .map((url) => url.name)
                .join(', ') :
            'No Urls Selected'
    ), [selectedValidUrls]);

    return (
        <Select<number[]>
            multiple
            value={selectedValidUrlIds}
            renderValue={renderValue}
            onChange={handleValidUrlsChange}
            displayEmpty
        >
            {allValidUrlsOptions.map(({ id, name }) => (
                <MenuItem
                    key={name}
                    value={id}
                >
                    {name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default UrlSelector;