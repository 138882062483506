import React from 'react';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { ALERT_DURATION, SUCCESS_CODE } from '../../constants/common';

interface AlertMessageProps {
    code: number;
    message: string | string[];
    setMessage: any;
    style?: React.CSSProperties;
}

const AlertMessage: React.FC<AlertMessageProps> = ({
    code,
    message,
    setMessage,
    style
}) => {
    return (
        <Snackbar
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            open={!!message}
            autoHideDuration={ALERT_DURATION}
            onClose={() => setMessage(null)}
        >
            <Alert
                style={{ margin: 'auto', ...style }}
                variant="outlined"
                severity={code === SUCCESS_CODE ? 'success' : 'error'}
            >
                <AlertTitle>
                    {code === SUCCESS_CODE ? 'Success' : 'Error'}
                </AlertTitle>
                {Array.isArray(message)
                    ? message[0].split(': ').at(-1)
                    : message}
            </Alert>
        </Snackbar>
    );
};

export default AlertMessage;
