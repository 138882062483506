/* eslint-disable no-unused-vars */

import { Box } from '@mui/material';
import { THEME_COLORS } from 'constants/colors';
import React from 'react';

export enum WrapperType {
    PAGE = 'page',
    TABLE = 'table',
}

const defaultStyles = {
    mb: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '16px',
    border: `1px solid ${THEME_COLORS.primaryEnaibledBorder}`,
};

const styles = {
    [WrapperType.PAGE]: {
        ...defaultStyles,
        p: '40px 200px',
        gap: '24px',
    },
    [WrapperType.TABLE]: {
        ...defaultStyles,
        p: '2rem',
        gap: '8px',
    },
};

interface PageWrapperProps {
  children?: React.ReactNode;
  type?: WrapperType;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children, type = WrapperType.PAGE }) => {
    return (
        <Box sx={styles[type]}>
            {children}
        </Box>
    );
};

export default PageWrapper;