import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import NavsLayout from './layouts/NavsLayout';
import NotFoundPage from './pages/NotFound';
import Categories from 'pages/Categories';
import Settings from 'pages/Settings';
import { ROUTES } from 'constants/common';
import Users from 'pages/Users';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path={ROUTES.Login}
                    element={
                        <NavsLayout body={<Login />} isProtected={false} />
                    }
                />
                <Route
                    path="/"
                    element={<Navigate to={ROUTES.Login} replace={true} />}
                />
                <Route
                    path={ROUTES.NotFound}
                    element={
                        <NavsLayout
                            body={<NotFoundPage />}
                            isProtected={false}
                        />
                    }
                />
                <Route
                    path={ROUTES.Categories}
                    element={
                        <NavsLayout body={<Categories />} isProtected={true} />
                    }
                />
                <Route
                    path={ROUTES.Settings}
                    element={
                        <NavsLayout body={<Settings />} isProtected={true} />
                    }
                />
                <Route
                    path={ROUTES.Users}
                    element={
                        <NavsLayout body={<Users />} isProtected={true} />
                    }
                />
                <Route
                    path="*"
                    element={<Navigate to={ROUTES.NotFound} replace />}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
