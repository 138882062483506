import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface SortByProps {
    sortBy: string;
    setSortBy: any;
}

const SortBy: React.FC<SortByProps> = ({ sortBy, setSortBy }) => {
    const handleChange = (event: SelectChangeEvent) => {
        setSortBy(event.target.value);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel>Sort by</InputLabel>
            <Select
                value={sortBy}
                label="Sort by"
                sx={{ width: '156px' }}
                onChange={handleChange}
            >
                <MenuItem value={'desc'}>Newest First</MenuItem>
                <MenuItem value={'asc'}>Oldest First</MenuItem>
            </Select>
        </FormControl>
    );
};

export default SortBy;
