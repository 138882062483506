import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingContainer = () => {
    return (
        <Box 
            display={'flex'} 
            justifyContent={'space-around'}
        >
            <CircularProgress />
        </Box>
    );
};

export default LoadingContainer;