import { createTheme } from '@mui/material';
import { THEME_COLORS } from '../constants/colors';
import '../styles/common.css';

export const appTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1025,
            xl: 1300
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    height: '100%',
                    width: '100%'
                }
            }
        },

        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            }
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'SF Pro Display',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '16px'
                }
            }
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    textTransform: 'uppercase'
                },
                containedSecondary: {
                    backgroundColor: THEME_COLORS.secondaryMain,
                    color: THEME_COLORS.textPrimary
                },
                sizeSmall: {
                    fontFamily: 'SF Pro Display',
                    fontSize: '0.75rem'
                },
                sizeMedium: {
                    fontFamily: 'SF Pro Display',
                    fontSize: '0.875rem'
                },
                sizeLarge: {
                    fontFamily: 'SF Pro Display',
                    fontSize: '0.938rem',
                    lineHeight: '26px',
                    letterSpacing: '0.46px',
                    textTransform: 'uppercase',
                }
            }
        },

        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: THEME_COLORS.textPrimary,
                        paddingBottom: '15x',
                        opacity: 1
                    }
                }
            }
        },

        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: THEME_COLORS.primarySelected,
                    paddingTop: '55px'
                }
            }
        },
        
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    textAlign: 'right',
                    marginRight: '0',
                    fontFamily: 'SF Pro Display',
                    fontWeight: 400,
                    fontSize: 12,
                    color: THEME_COLORS.textSecondary,
                }
            }
        },

        MuiSelect: {
            styleOverrides: {
                select: {
                    display: 'flex',
                    alignItems: 'center',

                    '&.MuiPaper-root': {
                        maxHeight: '200px',
                        backgroundColor: 'red'
                    }
                },
            }
        },

        MuiList: {
            styleOverrides: {
                root: {
                    padding: '0.5rem'
                }
            }
        },

        MuiTableCell: {
            styleOverrides: {
                head: {
                    textTransform: 'uppercase',
                    fontWeight: 700,
                }
            }
        },

        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '16px',
                    width: '100%'
                }
            }
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                }
            }
        },

        MuiListItemButton: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    '&.Mui-selected': {
                        '& svg': {
                            color: '#161616'
                        }
                    }
                }
            }
        },

        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: THEME_COLORS.textSecondary,
                    minWidth: '35px'
                }
            }
        },

        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    fontSize: '16px',
                    fontWeight: 300,
                    fontFamily: 'SF Pro Display',
                    boxShadow: '0px 2px 15px rgba(0, 91, 59, 0.1)',
                    backgroundColor: THEME_COLORS.primaryContrast,
                },
                icon: {
                    alignItems: 'center',
                    '& svg': {
                        width: '1.7rem',
                        height: '1.7rem'
                    }
                },
                message: {
                    fontFamily: 'SF Pro Display',
                    fontSize: '16px',
                    fontWeight: 400,
                },
                outlinedError: {
                    color: THEME_COLORS.errorMain,
                },
                outlinedSuccess: {
                    color: THEME_COLORS.successMain,
                }
            }
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    display: 'none'
                }
            }
        },

        MuiTypography: {
            styleOverrides: {
                root: {
                    wordBreak: 'initial'
                }
            }
        }
    },

    palette: {
        primary: {
            main: THEME_COLORS.primaryMain,
            dark: THEME_COLORS.primaryDark,
            light: THEME_COLORS.primaryLight,
            contrastText: THEME_COLORS.primaryContrast
        },
        secondary: {
            main: THEME_COLORS.secondaryMain,
            dark: THEME_COLORS.secondaryDark,
            light: THEME_COLORS.secondaryLight,
            contrastText: THEME_COLORS.secondaryContrast
        },
        error: {
            main: THEME_COLORS.errorMain,
            dark: THEME_COLORS.errorDark,
            light: THEME_COLORS.errorLight,
            contrastText: THEME_COLORS.primaryContrast
        },
        warning: {
            main: THEME_COLORS.warningMain,
            dark: THEME_COLORS.warningDark,
            light: THEME_COLORS.warningLight,
            contrastText: THEME_COLORS.secondaryContrast
        },
        success: {
            main: THEME_COLORS.successMain,
            dark: THEME_COLORS.successDark,
            light: THEME_COLORS.successLight,
            contrastText: THEME_COLORS.secondaryContrast
        },
        info: {
            main: THEME_COLORS.infoMain,
            dark: THEME_COLORS.infoDark,
            light: THEME_COLORS.infoLight,
            contrastText: THEME_COLORS.secondaryContrast
        },
        text: {
            primary: THEME_COLORS.textPrimary,
            secondary: THEME_COLORS.textSecondary,
            disabled: THEME_COLORS.textDisabled
        }
    },

    typography: {
        h1: {
            fontFamily: 'SF Pro Display',
            fontWeight: 300,
            fontSize: 96,
            lineHeight: '120%',
            color: THEME_COLORS.textPrimary
        },
        h2: {
            fontFamily: 'SF Pro Display',
            fontWeight: 300,
            lineHeight: '120%',
            fontSize: 60,
            color: THEME_COLORS.textPrimary
        },
        h3: {
            fontFamily: 'SF Pro Display',
            fontWeight: 400,
            lineHeight: '116.7%',
            fontSize: 48,
            color: THEME_COLORS.textPrimary
        },
        h4: {
            fontFamily: 'SF Pro Display',
            fontWeight: 700,
            lineHeight: '123.5%',
            fontSize: 34,
            color: THEME_COLORS.textPrimary
        },
        h5: {
            fontFamily: 'SF Pro Display',
            fontWeight: 600,
            lineHeight: '133.4%',
            fontSize: 24,
            color: THEME_COLORS.textPrimary
        },
        h6: {
            fontFamily: 'SF Pro Display',
            fontWeight: 500,
            lineHeight: '160%',
            fontSize: 20,
            color: THEME_COLORS.textPrimary
        },

        subtitle1: {
            fontFamily: 'SF Pro Display',
            fontWeight: 400,
            lineHeight: '175%',
            fontSize: 16,
            color: THEME_COLORS.textPrimary
        },
        subtitle2: {
            fontFamily: 'SF Pro Display',
            fontWeight: 500,
            lineHeight: '157%',
            fontSize: 14,
            color: THEME_COLORS.textPrimary
        },

        body1: {
            fontFamily: 'SF Pro Display',
            fontWeight: 400,
            lineHeight: '150%',
            fontSize: 16,
            color: THEME_COLORS.textPrimary
        },

        body2: {
            fontFamily: 'SF Pro Display',
            fontWeight: 400,
            lineHeight: '143%',
            fontSize: 14,
            color: THEME_COLORS.textPrimary
        },

        buttonSmall: {
            fontFamily: 'SF Pro Display',
            fontSize: 12,
            fontWeight: 500
        },
        buttonMiddle: {
            fontFamily: 'SF Pro Display',
            fontSize: 14,
            fontWeight: 600
        },
        buttonLarge: {
            fontFamily: 'SF Pro Display',
            fontSize: 15,
            fontWeight: 500
        }
    }
});
