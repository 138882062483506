export const UsersColumns = [
    'No.',
    'Sparkeey ID', 
    'First Name', 
    'Last Name', 
    'Email', 
    'Mobile', 
    'Country', 
    'State/Region', 
    'City', 
    'Social Handlers',
    'Created At',
    'Actions',
];