import { Box, IconButton, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React from 'react';


interface PageHeaderProps {
  title: string;
  onClick: any;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, onClick }) => {
    return (
        <Box flexDirection="row" display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
            <IconButton
                color="inherit"
                onClick={() => onClick()}
                edge="start"
            >
                <ChevronLeftIcon />
            </IconButton>
            <Typography variant='h5'> {title}</Typography>
        </Box>
    );
};

export default PageHeader;