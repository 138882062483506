import { Box, Button, Grid, Typography } from '@mui/material';
import { THEME_COLORS } from 'constants/colors';
import { ROUTES } from 'constants/common';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFoundPage() {
    const navigate = useNavigate();
    return (
        <Grid
            container
            height={'100vh'}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <Box
                alignItems={'center'}
                m="auto 0"
                p="50px 30px"
                textAlign={'center'}
                width={'50%'}
                sx={{
                    backgroundColor: THEME_COLORS.primaryContrast,
                    left: '50%',
                    top: '50%',
                    borderRadius: '16px',
                    border: '1px solid',
                    borderColor: THEME_COLORS.primaryEnaibledBorder
                }}
            >
                <Typography
                    fontWeight={500}
                    fontSize={50}
                    sx={{ color: THEME_COLORS.textPrimary }}
                >
                    Oops!
                </Typography>
                <Typography
                    fontWeight={400}
                    fontSize={30}
                    sx={{ color: THEME_COLORS.textPrimary }}
                >
                    Page not found!
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    sx={{ mt: '60px' }}
                    onClick={() => navigate(ROUTES.Login)}
                >
                    back to home
                </Button>
            </Box>
        </Grid>
    );
}
