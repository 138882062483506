import { TextField } from '@mui/material';
import React from 'react';

const MIN_NUMBER_VALUE = 1;

interface SettingInputProps {
  value: number;
  onChange: React.Dispatch<React.SetStateAction<number>>;
}

const SettingInput: React.FC<SettingInputProps> = ({ 
    value,
    onChange,
}) => {
    const onBlur = () => {
        if (value < MIN_NUMBER_VALUE) {
            onChange(MIN_NUMBER_VALUE);
        }
    };

    return (
        <TextField
            name="value"
            type="number"
            required
            variant="outlined"
            value={value.toString()}
            onChange={(e) => onChange(Number(e.target.value))}
            sx={{ width: '100%' }}
            inputProps={{ min: MIN_NUMBER_VALUE }}
            onBlur={onBlur}
        />
    );
};

export default SettingInput;