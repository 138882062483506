import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { uppercaseFirstLetter } from 'utils/string.utils';

interface SocialHandlerProps {
  name: string;
  size?: string;
}

const SocialHandler: React.FC<SocialHandlerProps> = ({
    name,
    size = '16px',
}) => {
    return (
        <Tooltip 
            title={uppercaseFirstLetter(name)}
            placement="top"
            arrow
        >
            <Box
                component="img"
                sx={{
                    width: size,
                    height: size
                }}
                alt={name}
                src={`/images/${name.toLowerCase()}.png`}
            />
        </Tooltip>
    );
};

export default SocialHandler;