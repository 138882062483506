import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';


interface AgreeDialogProps {
    open: boolean;
    close: any;
    title: string;
    description: string;
    submitButton: string;
    style?: React.CSSProperties;
    onConfirm: any;
    loading?: boolean;
}

const AgreeDialog: React.FC<AgreeDialogProps> = ({
    open,
    close,
    title,
    description,
    submitButton,
    onConfirm,
    loading,
}) => {
    const handleClose = loading ? () => null : close;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <Box p={'8px 16px'}>
                <DialogTitle p={'16px 24px'}>
                    {title}
                </DialogTitle>
                <DialogContent sx={{padding: '0px 24px 20px 24px'}}>
                    <DialogContentText>
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: '8px' }}>
                    <Button
                        onClick={handleClose}
                        autoFocus
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        color="error"
                        onClick={onConfirm}
                        loading={loading}
                    >
                        {submitButton}
                    </LoadingButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
};


export default AgreeDialog;
