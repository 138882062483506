import axiosService from 'requests/axios';
import { v4 as uuidv4 } from 'uuid';


export interface ImageOptions {
    src: string | null;
    blob: Blob | null;
}

export const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => {
            console.error('Error loading image:', error);
            reject(error);
        });
        image.src = url;
    });

export function getRadianAngle(degreeValue: number): number {
    return (degreeValue * Math.PI) / 180;
}

interface Size {
    width: number
    height: number
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number): Size {
    const rotRad = getRadianAngle(rotation);

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
}

interface PixelCrop {
    x: number
    y: number
    width: number
    height: number
}

interface Flip {
    horizontal: boolean
    vertical: boolean
}

export default async function getCroppedImg(
    imageSrc: string,
    pixelCrop: PixelCrop,
    rotation = 0,
    flip: Flip = { horizontal: false, vertical: false }
): Promise<ImageOptions | null> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        return null;
    }

    const rotRad = getRadianAngle(rotation);

    const {width: bBoxWidth, height: bBoxHeight} = rotateSize(
        image.width,
        image.height,
        rotation
    );

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    );

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(data, 0, 0);

    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            if (file) {
                resolve({src: URL.createObjectURL(file), blob: file});
            }

            resolve({src: '', blob: null});
        }, 'image/png');
    });
}
  
export const uploadCroppedImage = (croppedImage: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (croppedImage) {
            const formData = new FormData();
            const fileName = `${uuidv4()}.jpg`;
            formData.append('file', croppedImage, fileName);
        
            axiosService.post('assets/upload/image/', formData)
                .then((res: any) => {
                    resolve(res.data.location);
                })
                .catch((err: any) => {
                    reject(err);
                });
        } else {
            reject(new Error('No image provided'));
        }
    });
};