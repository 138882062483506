import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { THEME_COLORS } from 'constants/colors';
import { useMediaQuery, useTheme } from '@mui/material';

interface SearchBarProps {
    setSearch: any;
    placeholder?: string; 
}

const SearchBar: React.FC<SearchBarProps> = ({ setSearch, placeholder = 'Search by Name' }) => {
    const theme = useTheme();
    const upMobile = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <TextField
            variant="outlined"
            size="small"
            label="Search"
            name="Search"
            placeholder={placeholder}
            sx={{ height: '40px' }}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
                endAdornment: upMobile && (
                    <InputAdornment position="end">
                        <SearchIcon sx={{ color: THEME_COLORS.primaryOutlinedBorder, padding: '8px 4px 8px 8px' }} />
                    </InputAdornment>
                )
            }}
        />
    );
};

export default SearchBar;
