import React, { useState, useEffect } from 'react';
import {
    Box,
    IconButton,
    InputAdornment,
    Typography,
    useTheme,
    Button,
    TextField,
    Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosService from '../../requests/axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AlertMessageType, ERROR_CODE, ROUTES } from '../../constants/common';
import AlertMessage from '../../components/AlertMessage';
import { THEME_COLORS } from 'constants/colors';
import {
    getSavedAccessToken,
    saveAccessToken,
    saveRefreshToken,
    saveUserEmail,
    saveUserID
} from 'utils/localstorage.utils';

export default function LoginIndex() {
    const auth = getSavedAccessToken();
    const [message, setMessage] = useState<AlertMessageType | null>(null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();
    const theme = useTheme();
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    useEffect(() => {
        if (auth) {
            navigate(ROUTES.Categories);
        }
    }, []);

    const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        axiosService
            .post('auth/', { email, password })
            .then((res: any) => {
                saveAccessToken(res.data.accessToken);
                saveRefreshToken(res.data.refreshToken);
                saveUserID(res.data.user.id);
                saveUserEmail(res.data.user.email);
                setLoading(false);
                navigate(ROUTES.Categories);
            })
            .catch((err: any) => {
                const errorMessage = err?.response?.status === 403 ?
                    err.response.data?.message : 
                    'Wrong email or password!';

                setLoading(false);
                setMessage({
                    message: errorMessage,
                    code: ERROR_CODE
                });
            });
    };

    const submitDisabled = () => {
        return loading ? true : !(email && password) ? true : false;
    };

    return (
        <Box>
            <Box>
                {message && (
                    <AlertMessage
                        setMessage={setMessage}
                        message={message?.message}
                        code={message?.code}
                    />
                )}
            </Box>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ height: '100vh' }}
            >
                <Grid
                    container
                    direction="column"
                    alignItems={'left'}
                    margin={'0 auto'}
                    textAlign={'center'}
                    width={'552px'}
                    gap={'118px'}
                >
                    <Typography
                        variant="h4"
                        sx={{ color: (thema) => thema.palette.text.primary }}
                    >
                        Sparkeey
                    </Typography>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            alignItems="start"
                            justifyContent="center"
                            style={{
                                backgroundColor:
                                    theme.palette.primary?.contrastText,
                                border: '1px solid',
                                borderColor: THEME_COLORS.primaryEnaibledBorder,
                                padding: '24px 40px 40px 40px',
                                borderRadius: '16px'
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    marginBottom: '24px',
                                    color: (thema) => thema.palette.text.primary
                                }}
                            >
                                Log in
                            </Typography>
                            <form
                                onSubmit={handleLogin}
                                autoComplete="no"
                                style={{ width: '100%' }}
                            >
                                <Grid item>
                                    <TextField
                                        label="Email address"
                                        name="email"
                                        required
                                        type="email"
                                        variant="outlined"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Password"
                                        name="password"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        variant="outlined"
                                        required
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        style={{
                                            marginTop: '20px',
                                            width: '100%'
                                        }}
                                        size='medium'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        sx={{
                                                            color: THEME_COLORS.primaryOutlinedBorder
                                                        }}
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        size="medium"
                                        disabled={submitDisabled()}
                                        sx={{
                                            marginTop: '20px',
                                            width: '100%',
                                            boxShadow:
                                                '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);'
                                        }}
                                    >
                                        Log in
                                    </Button>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
