import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { API_URL, ROUTES } from '../constants/common';
import {
    getSavedAccessToken,
    getSavedRefreshToken,
    saveAccessToken,
    saveRefreshToken
} from 'utils/localstorage.utils';

const axiosService = axios.create({
    baseURL: API_URL
});

axiosService.interceptors.request.use(async (config) => {
    const token = getSavedAccessToken();

    if (token !== null) {
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
});

axiosService.interceptors.response.use(
    (res) => {
        return Promise.resolve(res);
    },
    (err) => {
        return Promise.reject(err);
    }
);

const refreshAuthLogic = async (failedRequest: {
    response: { config: { headers: { Authorization: string } } };
}) => {
    const refreshToken = getSavedRefreshToken();
    if (refreshToken !== null) {
        return axios
            .post(
                '/auth/refresh-token/',
                {
                    refreshToken: refreshToken
                },
                {
                    baseURL: API_URL
                }
            )
            .then((resp) => {
                const { accessToken, refreshToken } = resp.data;
                failedRequest.response.config.headers.Authorization =
                    'Bearer ' + accessToken;
                saveAccessToken(accessToken);
                saveRefreshToken(refreshToken);
            })
            .catch(() => {
                localStorage.clear();
                window.location.href = ROUTES.Login;
                
            });
    }
};

createAuthRefreshInterceptor(axiosService, refreshAuthLogic);

export default axiosService;
