export const API_URL = process.env.REACT_APP_API_URL;

export const SUCCESS_CODE = 200;
export const ERROR_CODE = 400;

export const ALERT_DURATION = 3000;

export interface AlertMessageType {
    message: string | string[];
    code: number;
}

export const LOCALSTORAGE_KEYS = {
    USER_ID: 'user_id',
    ACCEESS_TOKEN: 'access_token',
    REFRESH_TOKEN: 'refresh_token',
    USER_EMAIL: 'user_email'
};

export const ROUTES = {
    Login: '/login',
    Categories: '/categories',
    NotFound: '/not-found',
    Settings: '/settings',
    Users: '/users'
};

export const CATEGORIES_PAGE_MODE = {
    GET: 'get',
    ADD: 'add',
    EDIT: 'edit'
};
