import { Box } from '@mui/material';
import SearchBar from 'components/SearchBar';
import SortBy from 'components/SortBy';
import React, { useRef } from 'react';

interface UsersFiltersProps {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  sortBy: string;
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
}

const UsersFilters: React.FC<UsersFiltersProps> = ({
    setSearch,
    sortBy,
    setSortBy,
}) => {
    const searchTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const onSearchChange = (value: string) => {
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        if (!value) {
            setSearch(value);
            return;
        }

        searchTimeoutRef.current = setTimeout(() => {
            setSearch(value);
            searchTimeoutRef.current = null;
        }, 500);
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <SearchBar
                setSearch={onSearchChange}
                placeholder="Name, email, etc..."
            />
            <SortBy
                setSortBy={setSortBy}
                sortBy={sortBy}
            />
        </Box>
    );
};

export default UsersFilters;