export const formatDateString = (isoString: string) => {
    const date = new Date(isoString);
    
    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getUTCFullYear();
    
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const hour12 = hours % 12 || 12;
    const minuteString = minutes < 10 ? `0${minutes}` : minutes.toString();

    const daySuffix = (d: number) => {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
        }
    };

    const formattedDate = `${day}${daySuffix(day)} ${month} ${year}, ${hour12}:${minuteString} ${ampm}`;
    
    return formattedDate;
};
