import { Box, Button, TableCell, TableRow } from '@mui/material';
import SocialHandler from 'components/SocialHandler';
import { THEME_COLORS } from 'constants/colors';
import { User } from 'interfaces/common/user';
import React from 'react';
import { formatDateString } from 'utils/other.utils';

interface UsersTableRowProps {
  user: User;
  index: number;
  handleUserBlock: () => void;
  handleUserDelete: () => void;
}

const UsersTableRow: React.FC<UsersTableRowProps> = ({
    user,
    index,
    handleUserBlock,
    handleUserDelete,
}) => {
    return (
        <TableRow 
            hover 
            role="checkbox" 
            tabIndex={-1} 
            key={user.id} 
            sx={{ 
                whiteSpace: 'nowrap',
                height: '68px',
                ...(user.blocked && {
                    backgroundColor: THEME_COLORS.primarySelected,
                })
            }}
        >
            <TableCell>{index}</TableCell>
            <TableCell>{user.sparkeeyId || 'N/A'}</TableCell>
            <TableCell>{user.firstName}</TableCell>
            <TableCell>{user.lastName}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.mobile}</TableCell>
            <TableCell>{user.country}</TableCell>
            <TableCell>{user.state || 'N/A'}</TableCell>
            <TableCell>{user.city || 'N/A'}</TableCell>
            <TableCell>
                <Box
                    display="flex"
                    gap={1}
                >
                    {!user.socialHandlers?.length ?
                        'N/A' :
                        user.socialHandlers.map((socialHandler) => (
                            <SocialHandler
                                key={socialHandler.id}
                                name={socialHandler.name}
                            />
                        ))}
                </Box>
            </TableCell>
            <TableCell> {formatDateString(user.createdAt)} </TableCell>
            <TableCell width={'5%'}>
                <Box display={'flex'} flexDirection={'row'} gap={'16px'}>
                    <Button 
                        size='small' 
                        variant='outlined' 
                        color='primary'
                        onClick={handleUserBlock}
                    >
                        {user.blocked ? 'Unblock' : 'Block'}
                    </Button>
                    <Button 
                        size='small' 
                        variant='outlined' 
                        color='error' 
                        onClick={handleUserDelete}
                    >
            Delete
                    </Button>
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default UsersTableRow;