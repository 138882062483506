import React from 'react';
import { Box, Typography } from '@mui/material';
import { THEME_COLORS } from 'constants/colors';
import Divider from '@mui/material/Divider';



export const Footer = () => {
    return (
        <Box position="relative" width="100%" height={'80px'}>
            <Divider/>
            <Box display={'flex'} flexDirection={'column'} p={'16px 0'} alignItems={'flex-start'}>
                <Typography variant='body1'>Sparkeey</Typography>
                <Typography variant='body2' sx={{color: THEME_COLORS.textSecondary}}>Copyright © 2024 sparkeey.com</Typography>
            </Box>
        </Box>
    );
};
