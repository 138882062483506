import { AlertMessageType, ERROR_CODE, SUCCESS_CODE } from 'constants/common';
import { useCallback, useState } from 'react';
import axiosService from 'requests/axios';

interface UseUpdateAppConfigurationProps {
  maxInvitationAllowed: number;
  maxDurationAllowed: number;
  minAppVersion: string;
  validUrls: number[]
}

export const useUpdateAppConfiguration = (body: UseUpdateAppConfigurationProps) => {
    const [updateAppConfigurationMessage, setUpdateAppConfigurationMessage] = useState<React.SetStateAction<AlertMessageType | null>>(null);
    const [isUpdateAppConfigurationLoading, setIsUpdateAppConfigurationLoading] = useState<boolean>(false);

    const updateAppConfiguration = useCallback(() => {
        setIsUpdateAppConfigurationLoading(true);
        axiosService.patch('app-configuration', body)
            .then(() => {
                setUpdateAppConfigurationMessage({
                    message: 'App configuration successfully updated!',
                    code: SUCCESS_CODE
                });
            })
            .catch(() => {
                setUpdateAppConfigurationMessage({
                    message: 'Something went wrong!',
                    code: ERROR_CODE
                });
            })
            .finally(() => {
                setIsUpdateAppConfigurationLoading(false);
            });
    }, [body]);

    return {
        updateAppConfiguration,
        isUpdateAppConfigurationLoading,
        updateAppConfigurationMessage,
    };
};