import { AlertMessageType, ERROR_CODE } from 'constants/common';
import { ValidUrl } from 'interfaces/common/validUrl';
import { useCallback, useEffect, useState } from 'react';
import axiosService from 'requests/axios';

export const useValidUrlsOptions = () => {
    const [allValidUrlsOptions, setAllValidUrlsOptions] = useState<ValidUrl[]>([]);
    const [validUrlsLoading, setValidUrlsLoading] = useState<boolean>(false);
    const [validUrlsError, setValidUrlsError] = useState<AlertMessageType | null>(null);

    const receiveAllUrls = useCallback(() => {
        setValidUrlsLoading(true);
        axiosService.get('valid-urls/all')
            .then((res) => {
                setAllValidUrlsOptions(res.data);
            })
            .catch(() => {
                setValidUrlsError({
                    message: 'Something went wrong!',
                    code: ERROR_CODE
                });
            })
            .finally(() => {
                setValidUrlsLoading(false);
            });
    }, []);

    useEffect(() => {
        receiveAllUrls();
    }, [receiveAllUrls]);

    return {
        allValidUrlsOptions,
        validUrlsLoading,
        validUrlsError,
    };
};