export const THEME_COLORS = {
    textPrimary: 'rgba(22, 22, 22, 0.87)',
    textSecondary: 'rgba(22, 22, 22, 0.6)',
    textDisabled: 'rgba(22, 22, 22, 0.38)',

    primaryMain: '#161616',
    primaryDark: '#363636',
    primaryLight: '#676767',
    primaryContrast: '#FFFFFF',
    primaryHover: 'rgba(22, 22, 22, 0.04)',
    primarySelected: 'rgba(22, 22, 22, 0.08)',
    primaryFocus: 'rgba(22, 22, 22, 0.12)',
    primaryFocusVisible: 'rgba(22, 22, 22, 0.3)',
    primaryOutlinedBorder: 'rgba(22, 22, 22, 0.56)',
    primaryEnaibledBorder: 'rgba(22, 22, 22, 0.23)',

    secondaryMain: '#F4C115',
    secondaryDark: '#F3A900',
    secondaryLight: '#F6CD45',
    secondaryContrast: '#161616',

    errorMain: '#D32F2F',
    errorDark: '#C62828',
    errorLight: '#EF5350',

    warningMain: '#EF6C00',
    warningDark: '#EF6C00',
    warningLight: '#FF9800',

    infoMain: '#0288D1',
    infoDark: '#01579B',
    infoLight: '#03A9F4',

    successMain: '#2E7D32',
    successDark: '#1B5E20',
    successLight: '#4CAF50'
};
