import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import AlertMessage from 'components/AlertMessage';
import { Footer } from 'components/Footer';
import { AlertMessageType } from 'constants/common';
import MenuLayout from 'layouts/MenuLayout';
import React, { useState } from 'react';
import UsersTable from './components/UsersTable';

const Users = () => {
    const [message, setMessage] = useState<AlertMessageType | null>();

    return (
        <MenuLayout>
            {message && (
                <AlertMessage
                    setMessage={setMessage}
                    message={message?.message}
                    code={message?.code}
                />
            )}
            <Box>
                <Typography
                    variant="h5"
                    pb="18px"
                >
          Users
                </Typography>
                <UsersTable setMessage={setMessage} />
            </Box>
            <Footer />
        </MenuLayout>
    );
};

export default Users;