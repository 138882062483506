import { Box, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AlertMessage from 'components/AlertMessage';
import LoadingContainer from 'components/LoadingContainer';
import PageWrapper from 'components/PageWrapper';
import { AlertMessageType, ERROR_CODE } from 'constants/common';
import MenuLayout from 'layouts/MenuLayout';
import React, { useCallback, useEffect, useState } from 'react';
import axiosService from 'requests/axios';
import { ValidUrl } from 'interfaces/common/validUrl';
import SettingContainer from './components/SettingContainer';
import SettingInput from './components/SettingInput';
import UrlSelector from './components/UrlSelector';
import { useValidUrlsOptions } from './hooks/useValidUrlsOptions';
import { Footer } from 'components/Footer';
import { useUpdateAppConfiguration } from './hooks/useUpdateAppConfiguration';

const Settings = () => {
    const [message, setMessage] = useState<AlertMessageType | null>(null);
    const [appConfigurationLoading, setAppConfigurationLoading] = useState<boolean>(false);

    const {
        allValidUrlsOptions,
        validUrlsLoading,
        validUrlsError,
    } = useValidUrlsOptions();

    const isLoading = appConfigurationLoading || validUrlsLoading;

    const [maxInvitationAllowed, setMaxInvitationAllowed] = useState<number>(5);
    const [maxDurationAllowed, setMaxDurationAllowed] = useState<number>(5);
    const [selectedValidUrlIds, setSelectedValidUrlIds] = useState<number[]>([]);
    const [minAppVersion, setMinAppVersion] = useState<string>('');

    const {
        updateAppConfiguration,
        isUpdateAppConfigurationLoading,
        updateAppConfigurationMessage,
    } = useUpdateAppConfiguration({
        maxInvitationAllowed,
        maxDurationAllowed,
        minAppVersion,
        validUrls: selectedValidUrlIds,
    });

    const receiveAppConfiguration = useCallback(() => {
        setAppConfigurationLoading(true);
        axiosService.post('app-configuration')
            .then((res) => {
                setMaxInvitationAllowed(res.data.maxInvitationAllowed);
                setMaxDurationAllowed(res.data.maxDurationAllowed);
                setMinAppVersion(res.data.minAppVersion);

                const validUrls = res.data.validUrls.map((url: ValidUrl) => url.id);
                setSelectedValidUrlIds(validUrls);
            })
            .catch(() => {
                setMessage({
                    message: 'Something went wrong!',
                    code: ERROR_CODE
                });
            }).finally(() => {
                setAppConfigurationLoading(false);
            });
    }, []);

    useEffect(() => {
        receiveAppConfiguration();
    }, [receiveAppConfiguration]);

    useEffect(() => {
        const message = updateAppConfigurationMessage || validUrlsError;
        if(message) {
            setMessage(message);
        }
    }, [updateAppConfigurationMessage, validUrlsError]);

    return (
        <MenuLayout>
            <Grid
                container
                direction="row"
                display={'flex'}
                justifyContent={'space-between'}
                pb='28px'
            >
                <Typography variant='h5'>
                    Sparkeey Configuration
                </Typography>
            </Grid>
            {isLoading && (
                <PageWrapper>
                    <LoadingContainer />
                </PageWrapper>
            )}
            <Box display={isLoading ? 'none' : 'block'}>
                <PageWrapper>
                    {message && (
                        <AlertMessage
                            setMessage={setMessage}
                            message={message?.message}
                            code={message?.code}
                        />
                    )}
                    <SettingContainer
                        title="Max Sparkeey members allowed"
                        subtitle="Configuration setting that determines the maximum number of members you can select when creating a group invitation for an event or activity."
                        InputComponent={
                            <SettingInput
                                value={maxInvitationAllowed}
                                onChange={setMaxInvitationAllowed}
                            />
                        }
                    />
                    <SettingContainer
                        title="Max Sparkeey duration allowed (months)"
                        subtitle="Configuration setting that defines the maximum time frame for scheduling events in advance on each user's calendar."
                        InputComponent={
                            <SettingInput
                                value={maxDurationAllowed}
                                onChange={setMaxDurationAllowed}
                            />
                        }
                    />
                    <SettingContainer
                        title="Valid URLs"
                        subtitle="Configuration setting specifies a list of supported virtual event platforms. When creating a Spark, only platforms listed in this field will be allowed."
                        InputComponent={
                            <UrlSelector
                                allValidUrlsOptions={allValidUrlsOptions}
                                selectedValidUrlIds={selectedValidUrlIds}
                                setSelectedValidUrlIds={setSelectedValidUrlIds}
                            />
                        }
                    />
                    <SettingContainer
                        title="Min App Version"
                        subtitle="Configuration setting that specifies the minimum version of the application required for compatibility. We will use force upgrade flow for older versions."
                        InputComponent={
                            <TextField
                                value={minAppVersion}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setMinAppVersion(e.target.value)}
                                error={!minAppVersion}
                            />
                        }
                    />
                    <Box display={'flex'} flexDirection={'row'} gap='24px' justifyContent={'flex-end'}>
                        <LoadingButton
                            size='medium'
                            color='secondary'
                            variant='contained'
                            type="submit"
                            onClick={updateAppConfiguration}
                            disabled={!minAppVersion}
                            loading={isUpdateAppConfigurationLoading}
                        >
                            Save Changes
                        </LoadingButton>
                    </Box>
                </PageWrapper>
            </Box>
            <Footer />
        </MenuLayout>
    );
};

export default Settings;